import styled from "@emotion/styled"
import React, { createRef, useEffect } from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import lottie from "lottie-web"
import animation from "../images/lottie.json"

const StyledHomeHero = styled.nav`
  background: ${colors.secondary201};
  z-index: 0;
  overflow-x: hidden;
  display: flex;
  height: 92vh;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 0em;
  .green {
    position: absolute;
    width: 100vw;
    left: 0;
    height: 50px;
    background: ${colors.primary2};
    bottom: 0em;
    z-index: -4;
  }
  .logo {
    margin: 60px 0px;
    width: 94px;
  }
  h1 {
    text-align: center;
    margin: 0em;
    max-width: 348px;
    margin-top: 0px;
    z-index: 20;
    font-size: 36px;
    font-weight: 300;
    letter-spacing: -1.88px;
    line-height: 35px;
    text-align: center;
    color: ${colors.primary2};
    font-family: ${fonts.secondary};
  }
  p {
    margin: 0em;
  }
  .lottie {
    position: absolute;
    bottom: 40px;
    padding: 0em;
    margin: 0;
    z-index: 1;
    width: 1100px;
    left: 0em;
    animation: animation-shift 20s linear infinite forwards;
  }
  @keyframes animation-shift {
    0% {
      transform: translateX(00%);
    }

    50% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(00%);
    }
  }
  /* regular ipad */
  @media ${device.tablet} and (max-width: 1020px) and (orientation: portrait) {
    .lottie {
      position: absolute;
      bottom: 0em;
      width: 200vw;
      left: 0em;
      z-index: 1;
      animation: animation-shift 29s linear infinite forwards;
    }
    .hero-container {
      position: relative;
      height: 900px;
    }
    h1 {
      max-width: 428px;
      margin-top: 1em;
    }
  }
  @media ${device.tablet} and (orientation: landscape) {
    height: 104vh;

    h1 {
      font-size: 60px;
      line-height: 60px;
      font-weight: 300;
      max-width: 588px;
      margin-top: 2.75em;
      z-index: 1;
    }
    .logo {
      display: none;
    }
    .lottie-container {
      position: absolute;
      z-index: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
    }

    .lottie {
      width: 100%;
      /* top: 0;
      bottom: 0;
      left: 0;
      right: 0; */
      /* position: absolute; */
      margin-top: 30px;
      animation: none;
    }
  }
  @media ${device.desktop} {
    h1 {
      margin-top: 180px;
    }
  }
  @media ${device.desktopL} {
    h1 {
      margin-top: 165px;
    }
  }
`

export const HomeHero = () => {
  let animationContainer = createRef()
  useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer.current, // the dom element that will contain the animation
      renderer: "svg",
      animationData: animation,
      loop: true,
      autoplay: true,
      path: "../images/lottie.json", // the path to the animation json
    })
  }, [])

  return (
    <StyledHomeHero>
      {" "}
      <StaticImage
        className="logo"
        src="../images/logos/logo-regular.svg"
        placeholder="none"
        quality={100}
        alt="MLIFE logo"
      />
      <h1>We are home. Whomever you are. </h1>
      <div className="lottie-container">
        <div className="lottie" ref={animationContainer}></div>
      </div>
      {/* <StaticImage
        src="../images/home-banner.svg"
        placeholder="none"
        quality={100}
        alt="variety of animation people"
      /> */}
      <div className="green"></div>
    </StyledHomeHero>
  )
}
